import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useEffect, useRef } from 'react';
const DELAY_MS = 100;
NProgress.configure({
  showSpinner: false
});
const PageProgress = () => {
  const router = useRouter();
  const timeoutId = useRef<ReturnType<typeof setTimeout> | undefined>();
  const handleRouteChangeStart = () => {
    timeoutId.current = setTimeout(() => {
      NProgress.start();
    }, DELAY_MS);
  };
  const handleRouteChangeEnd = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    NProgress.done();
  };
  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);
    router.events.on('routeChangeError', handleRouteChangeEnd);
    return () => {
      handleRouteChangeEnd();
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
      router.events.off('routeChangeError', handleRouteChangeEnd);
    };
  },
  // https://github.com/vercel/next.js/issues/18127
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return null;
};
export default PageProgress;